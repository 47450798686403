<template>
  <div>
    <div class="container-fluid pt-5 pt-lg-15 pb-10">
      <div class="content flex-row-fluid">
        <div class="card">
          <div class="card-body p-6">
            <div class="position-relative rounded ratio ratio-16x9 overflow-hidden">
              <googlemaps-map
                ref="map"
                class="position-absolute"
                :center.sync="center"
                :zoom="zoom"
                :options="{
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  overviewMapControl: false,
                  rotateControl: false,
                }"
              >
                <googlemaps-user-position @update:position="setUserPosition" />
                <template v-if="pins.length">
                  <googlemaps-marker
                    v-for="project in pins"
                    :key="project.id"
                    :ref="project.id"
                    :position="{ lat: project.attributes.lat || 0, lng: project.attributes.lon || 0 }"
                    @click="selectMarker(project)"
                  />
                </template>
              </googlemaps-map>
              <px-loader v-if="isMapLoading" :is-full-window="false" bg="blue" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isMaster: 'auth/isMaster',
      pins: 'projects/mapList',
    }),
  },
  data() {
    return {
      isMapLoading: false,
      zoom: 12,
      center: {
        lat: 43.72,
        lng: -79.32,
      },
      userPosition: null,
      openedInfoWindows: [],
      infoWindow: null,
    };
  },
  async mounted() {
    const params = {};

    this.isMapLoading = true;

    if (!this.isMaster) params.manager = this.user.id;

    await this.actionProjectsMapList({ ...params, archive: false });

    // if no projects set Toronto as a center
    if (!this.pins.length) {
      this.zoom = 14;
      this.center = {
        lat: 43.6532,
        lng: -79.3832,
      };
    }

    // if only project set this project coords as a center
    if (this.pins.length === 1) {
      this.zoom = 14;
      this.center = {
        lat: this.pins[0].attributes.lat,
        lng: this.pins[0].attributes.lon,
      };
    }

    // eslint-disable-next-line no-undef
    this.infoWindow = new google.maps.InfoWindow();

    this.isMapLoading = false;
  },
  methods: {
    ...mapActions({
      actionProjectsMapList: 'projects/mapList',
    }),
    goTo(id) {
      const routeData = this.$router.resolve({ name: 'single-project', params: { id } });

      window.open(routeData.href, '_blank');
    },
    setUserPosition(position) {
      this.userPosition = position;
    },
    selectMarker(el) {
      const map = this.$refs.map.$_map;
      const marker = this.$refs[el.id][0].$_marker;

      this.infoWindow.setContent('');
      this.infoWindow.setContent(`<div class="mw-200px text-center lh-lg">
          <span id="g_map_link" class="fs-7 text-body text-hover-primary" role="button">${el.attributes.address}</span>
        </div>`);
      this.infoWindow.open(map, marker);

      setTimeout(() => {
        document.getElementById(`g_map_link`).addEventListener('click', () => this.goTo(el.id));
      });
    },
  },
};
</script>
